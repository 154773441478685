import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Card,
  CardContent,
  Switch,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import SideNavBar from "../../Commons/Sidebar";
import AWS from "aws-sdk";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ratio } from "fuzzball";
import { ClipLoader } from "react-spinners";
import DeleteIcon from "@mui/icons-material/Delete";
import WelcomeImage from "../../Assets/Welcome.jpg";

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
  marginTop: "2rem",
  width: "100%",
});

const StyledButton = styled(Button)({
  border: "1px solid #ff5f00",
  backgroundColor: "white",
  color: "#ff5f00",
  "&:hover": {
    backgroundColor: "#ffe6d9",
  },
});

const GenerateButton = styled(Button)({
  backgroundColor: "#ff5f00",
  color: "white",
  "&:hover": {
    backgroundColor: "#e65500",
  },
});

const StyledCard = styled(Card)({
  position: "relative",
  margin: "3%",
  padding: "1%",
  borderRight: "5px solid #ff5f00",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const StyledTypography = styled(Typography)({
  textAlign: "center",
  margin: "5%",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    fontSize: "0.875rem",
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "grey",
    },
    "&.Mui-focused fieldset": {
      borderColor: "grey",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "grey",
    fontSize: "0.875rem",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "grey",
  },
  marginBottom: "0.5rem",
});

const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-root": {
    fontSize: "0.875rem",
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "grey",
    },
    "&.Mui-focused fieldset": {
      borderColor: "grey",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "grey",
    fontSize: "0.875rem",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "grey",
  },
  marginBottom: "0.5rem",
});

const generateRandomId = () => {
  return Math.random().toString(36).substr(2, 9);
};

const partBelongsToTheAssembly = (modelName, assemblyContent) => {
  const excludeStep = modelName.split(".")[0];
  const splitOnDash = excludeStep.split("-").map((segment) => segment.trim());
  const smallCase = excludeStep.toLowerCase();
  const noSpaceName = [
    smallCase.replace(/ /g, "-"),
    smallCase.replace(/ /g, "_"),
    smallCase.replace(/ /g, ""),
  ];

  const possibleNames = [
    excludeStep,
    ...splitOnDash,
    ...noSpaceName,
    modelName,
    modelName.toLowerCase(),
    smallCase,
    excludeStep,
  ];

  for (const name of possibleNames) {
    const char = "'";
    for (const textSegment of assemblyContent.split(char)) {
      if (ratio(textSegment, name) > 80) {
        return true;
      }
    }
  }

  return false;
};

const UserDashboard = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    designIntent: "",
    manufacturingProcess: "CNC Machining with 3-axis",
    partMaterial: "Aluminum 6061",
    tolerancingGuidelines: "Standard",
    internationalStandard: "ISO 2768",
    toleranceFile: null,
    assemblyFile: null,
    modelFile: null,
    isDone: false,
    outputFile: null,
    userId: localStorage.getItem("userId"),
    userEmail: localStorage.getItem("userEmail"),
    modelFileName: "",
    assemblyFileName: "",
  });

  const [userFiles, setUserFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [svgContent, setSvgContent] = useState("");
  const [part, setPart] = useState("");
  const [isAssemblyFileRequired, setIsAssemblyFileRequired] = useState(false);
  const [showOtherMaterialField, setShowOtherMaterialField] = useState(false);
  const [loading, setLoading] = useState(false);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const params = {
          Bucket: "iad-interface",
          Prefix: "Form_Data/",
        };

        const data = await s3.listObjectsV2(params).promise();
        const jsonFiles = data.Contents.filter((file) =>
          file.Key.endsWith(".json")
        );

        const filePromises = jsonFiles.map((file) =>
          s3
            .getObject({ Bucket: "iad-interface", Key: file.Key })
            .promise()
            .then((res) => ({
              ...JSON.parse(res.Body.toString()),
              LastModified: file.LastModified,
            }))
        );

        const filesData = await Promise.all(filePromises);
        const filteredFiles = filesData.filter(
          (file) => file.userId === userId
        );

        filteredFiles.sort(
          (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
        );

        setUserFiles(filteredFiles);
      } catch (error) {
        console.error("Error fetching user files:", error);
      }
    };

    fetchUserFiles();
  }, []);

  const handleDelete = async (partId) => {
    try {
      const params = {
        Bucket: "iad-interface",
        Key: `Form_Data/${partId}.json`,
      };

      await s3.deleteObject(params).promise();
      console.log(`Deleted part with ID: ${partId}`);
      setUserFiles(
        userFiles.filter(
          (file) => file.modelFile.split("/").pop().split("_")[0] !== partId
        )
      );
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "partMaterial" && value === "Other") {
      setShowOtherMaterialField(true);
    } else if (name === "partMaterial") {
      setShowOtherMaterialField(false);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const fileName = files[0].name.replace(/\s+/g, "");
    setFormData({
      ...formData,
      [name]: files[0],
      [`${name}Name`]: fileName,
    });

    alert("File Selected");
  };

  const uploadFileToS3 = (file, key) => {
    const params = {
      Bucket: "iad-interface",
      Key: key,
      Body: file,
      ContentType: file.type,
    };

    return s3.upload(params).promise();
  };

  const uploadJsonToS3 = (data, key) => {
    const params = {
      Bucket: "iad-interface",
      Key: key,
      Body: JSON.stringify(data),
      ContentType: "application/json",
    };

    return s3.upload(params).promise();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userFiles.length >= 10) {
      alert("You can only upload a maximum of 10 files!");
      return;
    }

    setLoading(true);
    try {
      const uploadPromises = [];
      const partId = generateRandomId();

      if (formData.modelFile) {
        if (isAssemblyFileRequired && formData.assemblyFile) {
          const assemblyContent = await formData.assemblyFile.text();
          const modelFileName = formData.modelFile.name;

          if (!partBelongsToTheAssembly(modelFileName, assemblyContent)) {
            alert(
              "Please note that the model file does not belong to the assembly file"
            );
          }
        }

        uploadPromises.push(
          uploadFileToS3(
            formData.modelFile,
            `Models/${partId}_${formData.modelFile.name}`
          )
        );
      }
      if (formData.toleranceFile) {
        uploadPromises.push(
          uploadFileToS3(
            formData.toleranceFile,
            `Tolerances/${partId}_${formData.toleranceFile.name}`
          )
        );
      }
      if (isAssemblyFileRequired && formData.assemblyFile) {
        uploadPromises.push(
          uploadFileToS3(
            formData.assemblyFile,
            `Assemblies/${partId}_${formData.assemblyFile.name}`
          )
        );
      }

      const uploadResults = await Promise.all(uploadPromises);

      const formDataToUpload = {
        ...formData,
        modelFile: formData.modelFile ? uploadResults[0]?.Location : null,
        toleranceFile: formData.toleranceFile
          ? uploadResults[formData.modelFile ? 1 : 0]?.Location
          : null,
        assemblyFile:
          isAssemblyFileRequired && formData.assemblyFile
            ? uploadResults[
                formData.modelFile
                  ? formData.toleranceFile
                    ? 2
                    : 1
                  : formData.toleranceFile
                  ? 1
                  : 0
              ]?.Location
            : null,
      };

      const vmr = await fetch("https://algo.hanomi.ai/api/create_drawing", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataToUpload),
      });

      if (!vmr.ok) {
        throw new Error("Failed to Send Data to the API");
      }

      console.log("API Response:", await vmr.json());

      await uploadJsonToS3(formDataToUpload, `Form_Data/${partId}.json`);
      alert("Data uploaded. You can see the status in the above section");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = async (partId) => {
    try {
      const params = {
        Bucket: "iad-interface",
        Key: `Form_Data/${partId}.json`,
      };

      const data = await s3.getObject(params).promise();
      const fileData = JSON.parse(data.Body.toString());

      if (!fileData.isDone) {
        alert("You'll receive an email once the process is completed");
        return;
      }

      const svgResponse = await fetch(fileData.Output_File);
      const svgBlob = await svgResponse.blob();
      setSvgContent(svgBlob);
      setPart(partId);
      navigate("/userDrawing", {
        state: { partId, svgBlob, outputFile: fileData.Output_File },
      });
    } catch (error) {
      console.error("Error fetching file data:", error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    cssEase: "ease-in-out",
  };

  return (
    <>
      <SideNavBar />
      <Container maxWidth="sm" style={{ marginTop: "3%", marginBottom: "4%" }}>
        <Typography variant="h6">
          <b>Projects:</b>
        </Typography>
        <p style={{ fontSize: "14px", margin: "3% 0", textAlign: "justify" }}>
          Here you can find both your previously uploaded files and the current
          ones. Please click on <b>Check Status</b> for more information about
          the status of your current jobs.
        </p>
        <Slider {...settings} style={{ marginTop: "2rem" }}>
          <div>
            <StyledTypography variant="body1">
              <img src={WelcomeImage} style={{ width: "100%" }} />
            </StyledTypography>
          </div>
          {userFiles.length === 0 ? (
            <StyledTypography variant="body1">
              <center>No Past Work</center>
            </StyledTypography>
          ) : (
            userFiles.map((file, index) => (
              <div key={index}>
                <StyledCard>
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "#ff5f00",
                    }}
                    onClick={() =>
                      handleDelete(
                        file.modelFile.split("/").pop().split("_")[0]
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                  <CardContent>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      {file.modelFileName ? file.modelFileName : "Unknown"}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginTop: "0rem", fontStyle: "italic" }}
                    >
                      Last Updated:{" "}
                      {new Date(file.LastModified).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" style={{marginTop:"0.5rem"}}>
                      Design Intent: {file.designIntent}
                    </Typography>
                    <br />
                    <center>
                      <Button
                        variant="outlined"
                        style={{
                          borderRadius: "20px",
                          padding: "2%",
                          width: "50%",
                          borderColor: "#ff5f00",
                          color: "#ff5f00",
                        }}
                        onClick={() =>
                          handleCardClick(
                            file.modelFile.split("/").pop().split("_")[0]
                          )
                        }
                      >
                        {file.isDone ? "Open File" : "Check Status"}
                      </Button>
                    </center>
                  </CardContent>
                </StyledCard>
              </div>
            ))
          )}
        </Slider>
        <br />
        <Form onSubmit={handleSubmit}>
          <StyledButton variant="contained" component="label">
            Upload 3D Model
            <input
              type="file"
              hidden
              name="modelFile"
              accept=".step,.stp"
              onChange={handleFileChange}
            />
          </StyledButton>
          {formData.modelFile && (
            <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
              {formData.modelFile.name}
            </Typography>
          )}

          <Typography variant="h6" style={{ marginTop: "5%" }}>
            <b>Set Context:</b>
          </Typography>
          <p style={{ fontSize: "14px", textAlign: "justify" }}>
            <b>Example: Mounting Plate with Central Bore and Fastener Holes</b>
            <br />
            <br />
            Design Intent: "The Ø10 mm fastener holes must be concentric to the
            Ø50 mm central bore within 0.05 mm to ensure precise alignment with
            the motor shaft and maintain balanced load distribution during
            assembly."
          </p>
          <StyledTextField
            label="Design Intent"
            variant="outlined"
            name="designIntent"
            value={formData.designIntent}
            onChange={handleChange}
            required
          />

          <br />
          <FormControl variant="outlined">
            <InputLabel>Manufacturing Process</InputLabel>
            <StyledSelect
              name="manufacturingProcess"
              value={formData.manufacturingProcess}
              onChange={handleChange}
              label="Manufacturing Process"
              required
            >
              {[
                "CNC Machining with 3-axis",
                "CNC Machining with 5-axis",
                "Injection Molding",
                "Casting",
                "Extrusion",
                "Turning",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel>Part Material</InputLabel>
            <StyledSelect
              name="partMaterial"
              value={formData.partMaterial}
              onChange={handleChange}
              label="Part Material"
              required
            >
              {[
                "Aluminum 6061",
                "Aluminum 7075",
                "Stainless Steel 304",
                "Stainless Steel 316",
                "ABS",
                "Polypropylene (PP)",
                "Polyurethane",
                "Nylon",
                "Titanium",
                "Polycarbonate",
                "PEEK",
                "Carbon Steel 1045",
                "Polyethylene HDPE",
                "Polyethylene LDPE",
                "PLA",
                "Other",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          {showOtherMaterialField && (
            <StyledTextField
              label="Specify Material"
              variant="outlined"
              name="otherMaterial"
              value={formData.otherMaterial || ""}
              onChange={handleChange}
              required
            />
          )}

          <FormControl variant="outlined">
            <InputLabel>International Standards</InputLabel>
            <StyledSelect
              name="internationalStandard"
              value={formData.internationalStandard}
              onChange={handleChange}
              label="International Standards"
              required
            >
              {["ISO 2768", "ASME Y14.5"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <br />
          <p style={{ fontSize: "14px", textAlign: "justify", marginTop: "0" }}>
            <b>Note: </b>Please upload the subassembly to which the component
            belongs, so our software can provide more accurate results for GD&T.
          </p>
          <FormControlLabel
            control={
              <Switch
                checked={isAssemblyFileRequired}
                onChange={() =>
                  setIsAssemblyFileRequired(!isAssemblyFileRequired)
                }
                name="isAssemblyFileRequired"
                color="#ff5f00"
              />
            }
            label="Assembly File"
          />
          {isAssemblyFileRequired && (
            <StyledButton variant="contained" component="label">
              Upload Assembly File
              <input
                type="file"
                hidden
                name="assemblyFile"
                accept=".step,.stp"
                onChange={handleFileChange}
              />
            </StyledButton>
          )}
          {formData.assemblyFile && (
            <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
              {formData.assemblyFile.name}
            </Typography>
          )}

          <GenerateButton
            type="submit"
            variant="contained"
            style={{ marginTop: "5%" }}
          >
            {loading ? (
              <ClipLoader size={20} color="#fff" />
            ) : (
              "Generate 2D Drawings"
            )}
          </GenerateButton>
        </Form>
      </Container>
    </>
  );
};

export default UserDashboard;
