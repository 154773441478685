import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/system";
import SideNavBar from "../../Commons/Sidebar";
import AWS from "aws-sdk";
import { CloudDownload, CloudUpload } from "@mui/icons-material";
import emailjs from "@emailjs/browser";

const StyledButton = styled(Button)({
  border: "1px solid #ff5f00",
  backgroundColor: "white",
  color: "#ff5f00",
  "&:hover": {
    backgroundColor: "white",
  },
});

const DrafterDashboard = () => {
  const [userFiles, setUserFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      alert("Accepted Format: SVG");
      fileInputRef.current.click();
    } else {
      console.error("File input ref is not assigned");
    }
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const params = {
          Bucket: "iad-interface",
          Prefix: "Form_Data/",
        };

        const data = await s3.listObjectsV2(params).promise();
        const jsonFiles = data.Contents.filter((file) =>
          file.Key.endsWith(".json")
        );

        jsonFiles.sort(
          (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
        );

        const filePromises = jsonFiles.map((file) =>
          s3
            .getObject({ Bucket: "iad-interface", Key: file.Key })
            .promise()
            .then((res) => JSON.parse(res.Body.toString()))
        );

        const filesData = await Promise.all(filePromises);
        const filteredFiles = filesData.filter((file) => !file.isDone);

        setUserFiles(filteredFiles);
      } catch (error) {
        console.error("Error fetching user files:", error);
      }
    };

    fetchUserFiles();
  }, []);

  const uploadJsonToS3 = async (data, key) => {
    const params = {
      Bucket: "iad-interface",
      Key: key,
      Body: JSON.stringify(data),
      ContentType: "application/json",
    };

    try {
      const r = await s3.upload(params).promise();
    } catch (error) {
      console.error("Error uploading JSON to S3:", error);
    }
  };

  const handleDownload = async (outputFile) => {
    if (outputFile) {
      try {
        const response = await fetch(outputFile);
        if (!response.ok) {
          throw new Error("Network Error");
        }
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = outputFile.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
        alert("Error downloading the file");
      }
    } else {
      alert("Output file isn't available yet");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    alert("File Selected");
  };

  const handleUpload = async (file, key) => {
    if (!selectedFile) {
      alert(
        "Please select a file to upload. Upload the same file if the generated output is alright"
      );
      return;
    }

    const Part_Id = file.modelFile
      ? file.modelFile.split("/").pop().split("_")[0]
      : "Unknown";

    const uploadParams = {
      Bucket: "iad-interface",
      Key: `Output_Files/${Part_Id}_${selectedFile.name}`,
      Body: selectedFile,
      ContentType: "image/svg+xml",
    };

    try {
      const uploadResult = await s3.upload(uploadParams).promise();
      file.Output_File = uploadResult.Location;
      const updatedKey = `Form_Data/${Part_Id}.json`;
      await uploadJsonToS3(file, updatedKey);
      alert("File Uploaded");
    } catch (error) {
      console.error("Error:", error);
      alert("Error Uploading File");
    }
  };

  const handleSubmit = async (file) => {
    await handleUpload(file, `Form_Data/${file.Part_ID}.json`);

    file.isDone = true;

    const Part_Id = file.modelFile
      ? file.modelFile.split("/").pop().split("_")[0]
      : "Unknown";

    const updatedKey = `Form_Data/${Part_Id}.json`;

    await uploadJsonToS3(file, updatedKey);

    const timestamp = new Date().toLocaleString();

    let svgBase64 = "";
    try {
      const response = await fetch(file.Output_File);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        svgBase64 = reader.result.split(",")[1];

        emailjs
          .send(
            "service_httmolo",
            "template_8n1x7w4",
            {
              part_name: file.modelFileName,
              reply_to: file.userEmail,
              file: file.Output_File
            },
            "uLbz5Y2oEH_64ZBt2"
          )
          .then((response) => {
            console.log("Email Sent", response.status, response.text);
          })
          .catch((error) => {
            console.error("Failed to send email:", error);
          });
      };
    } catch (error) {
      console.error("Failed to fetch SVG file:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = userFiles.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <SideNavBar />
      <Container
        maxWidth="md"
        style={{ marginTop: "3%", marginBottom: "4%", marginLeft: "28%" }}
      >
        <Grid container spacing={2}>
          {currentItems.map((file, index) => (
            <Grid item xs={12} key={index} style={{ margin: "2% 0" }}>
              <Card
                style={{
                  padding: "1%",
                  borderRight: "10px solid #ff5f00",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    User ID: {file.userId}
                  </Typography>
                  <br />
                  <Typography variant="body2">
                    <ul>
                      <li>
                        <b>Part ID:</b>{" "}
                        {file.modelFile
                          ? file.modelFile.split("/").pop().split("_")[0]
                          : "Unknown"}
                      </li>
                    </ul>
                  </Typography>
                  <Typography variant="body2">
                    <ul>
                      <li>
                        <b>Part Name:</b>{" "}
                        {file.modelFileName ? file.modelFileName : "Unknown"}
                      </li>
                    </ul>
                  </Typography>
                  <Typography variant="body2">
                    <ul>
                      <li>
                        <b>Design Intent:</b> {file.designIntent}
                      </li>
                    </ul>
                  </Typography>
                  <br />
                  <center>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => handleDownload(file.Output_File)}
                        style={{
                          border: "1px solid #ff5f00",
                          borderRadius: "20px",
                          width: "30%",
                          backgroundColor: "white",
                          color: "black",
                          margin: "1%",
                          fontSize: "10px",
                          textTransform: "capitalize",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CloudDownload style={{ marginRight: "8px" }} />
                        <span>Download Draft 2D Drawing</span>
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleUploadClick}
                        style={{
                          border: "1px solid #ff5f00",
                          borderRadius: "20px",
                          width: "30%",
                          backgroundColor: "white",
                          color: "black",
                          margin: "1%",
                          fontSize: "10px",
                          textTransform: "capitalize",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CloudUpload style={{ marginRight: "8px" }} />
                        <span>Upload Updated 2D Drawing</span>
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleSubmit(file)}
                        style={{
                          border: "1px solid #ff5f00",
                          borderRadius: "20px",
                          width: "30%",
                          backgroundColor: "white",
                          color: "black",
                          margin: "1%",
                          fontSize: "10px",
                          textTransform: "capitalize",
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </center>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={Math.ceil(userFiles.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Container>
      <input
        type="file"
        accept=".svg"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
    </>
  );
};

export default DrafterDashboard;
