import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import { styled } from "@mui/material/styles";
import GoogleIcon from "@mui/icons-material/Google";
import Person from "@mui/icons-material/Person";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  color: "#ff5f00",
  backgroundColor: "#fff",
  border: "2px solid #ff5f00",
  borderRadius: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#f6f6f6",
    border: "1px solid #dadce0",
  },
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  color: "black",
  backgroundColor: "#e0e0e0",
  borderRadius: "10px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

const LoginTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ff5f00",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff5f00",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#ff5f00",
    },
  },
});

const Signup = () => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [signupType, setSignupType] = useState(null);
  const [adminCode, setAdminCode] = useState("");

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id:
          "813707322723-hvuhu27e259tuk60u7efk6l6a8u1qsv5.apps.googleusercontent.com",
        callback: handleGoogleResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInButton"),
        { theme: "outline", size: "large" }
      );
    };

    if (window.google && window.google.accounts) {
      initializeGoogleSignIn();
    } else {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    }
  }, []);

  const handleGoogleResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    setName(userObject.name);
    setEmail(userObject.email);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("role", role);
    formData.append("email", email);
    formData.append("password", password);

    try {
      const response = await axios.post(
        `https://backend.hanomi.ai/signup`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        alert("Signup Successful");
        window.location.href = "/";
      } else {
        alert("Incorrect Signup");
      }
    } catch (error) {
      alert(error.response.data);
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleAdminCodeSubmit = () => {
    if (adminCode === "434567") {
      setRole("Drafter");
      setSignupType("admin");
    } else if (adminCode === "212567") {
      setRole("Admin");
      setSignupType("admin");
    } else {
      alert("Invalid code");
    }
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      {matches && (
        <Grid
          item
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ textAlign: "center", marginTop: "30%" }}>
            <img
              src="https://i.ibb.co/NtXDNrR/image-1.png"
              alt=""
              style={{ width: "40%" }}
            />
          </Box>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography variant="body2">
              © Hanomi, Inc. 2024
              <br />
              All rights reserved
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={6}
        component="main"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <Container maxWidth="xs">
          <Paper
            elevation={0}
            sx={{
              mt: 8,
              display: "flex",
              flexDirection: "column",
              p: 2,
            }}
            style={{ padding: "5% 10%" }}
          >
            <b style={{ textAlign: "left", fontSize: "20px" }}>
              Getting Started
            </b>
            <p style={{ fontSize: "16px", marginTop: "2%" }}>
              Create an account to continue!
            </p>
            {!signupType && (
              <Box sx={{ mt: 1 }}>
                <StyledButton
                  fullWidth
                  onClick={() => {
                    setSignupType("user");
                    setRole("User");
                  }}
                >
                  Signup as User
                </StyledButton>
                <StyledButton
                  fullWidth
                  onClick={() => setSignupType("adminCode")}
                >
                  Signup as Admin
                </StyledButton>
              </Box>
            )}
            {signupType === "adminCode" && (
              <Box sx={{ mt: 1 }}>
                <LoginTextField
                  margin="normal"
                  fullWidth
                  id="adminCode"
                  label="Enter Multi-factor Authenticator Code"
                  name="adminCode"
                  autoComplete="off"
                  autoFocus
                  value={adminCode}
                  onChange={(e) => setAdminCode(e.target.value)}
                  variant="outlined"
                />
                <StyledSubmitButton fullWidth onClick={handleAdminCodeSubmit}>
                  Submit Code
                </StyledSubmitButton>
              </Box>
            )}
            {(signupType === "user" || signupType === "admin") && (
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <div id="googleSignInButton"></div>{" "}
                <Box sx={{ my: 2, display: "flex", alignItems: "center" }}>
                  <Box sx={{ flex: 1, height: "1px", bgcolor: "grey.300" }} />
                  <Typography variant="caption" sx={{ mx: 2 }}>
                    Signup with email
                  </Typography>
                  <Box sx={{ flex: 1, height: "1px", bgcolor: "grey.300" }} />
                </Box>
                <br />
                <LoginTextField
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ color: "#ff5f00" }}
                      >
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
                <LoginTextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ color: "#ff5f00" }}
                      >
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <LoginTextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ color: "#ff5f00" }}
                      >
                        <KeyIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          style={{ color: "#ff5f00" }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledSubmitButton
                  fullWidth
                  style={{ marginTop: "10%" }}
                  onClick={handleSubmit}
                >
                  <b>Signup</b>
                </StyledSubmitButton>
                <center>
                  {loader && (
                    <p style={{ color: "red" }}>Creating. Please Wait!</p>
                  )}
                  <Box
                    sx={{ justifyContent: "space-between", mt: 3 }}
                    style={{ marginTop: "3%" }}
                  >
                    Already Registered?
                    <Link
                      href="/"
                      style={{ color: "#ff5f00", textDecoration: "none" }}
                    >
                      <b> {"Login"}</b>
                    </Link>
                  </Box>
                </center>
              </Box>
            )}
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Signup;
