import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
import ForgotPassword from "./Components/Auth/ResetPassword";
import UserDashboard from "./Components/Dashboard/UserDashboard";
import DrafterDashboard from "./Components/Dashboard/DrafterDashboard";
import Viewer from "./Components/SVG/Viewer";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} exact />
          <Route path="/signup" element={<Signup />} exact />
          <Route path="/forgotPassword" element={<ForgotPassword />} exact />
          <Route path="/userDashboard" element={<UserDashboard />} exact />
          <Route path="/drafterDashboard" element={<DrafterDashboard />} exact />
          <Route path='/userDrawing' element={<Viewer/>} exact/>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
