import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Rating,
} from "@mui/material";
import AWS from "aws-sdk";
import axios from "axios";
import { CloudDownload, Save } from "@mui/icons-material";

const Viewer = () => {
  const location = useLocation();
  const { partId, svgBlob, outputFile } = location.state || {};
  const [svgUrl, setSvgUrl] = useState("");
  const [comment, setComment] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dxfId, setDxfId] = useState(null);
  const [dxfMinutes, setDxfMinutes] = useState(null);
  const [dxfButtonLabel, setDxfButtonLabel] = useState("Download DXF");
  const [rating, setRating] = useState(0);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    if (svgBlob) {
      const url = URL.createObjectURL(svgBlob);
      setSvgUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [svgBlob]);

  useEffect(() => {
    setOpenSnackbar(true);
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleCommentSave = async () => {
    try {
      const params = {
        Bucket: "iad-interface",
        Key: `Comments/${partId}.json`,
        Body: JSON.stringify({ comment, rating }),
        ContentType: "application/json",
      };
      await s3.putObject(params).promise();
      alert("Comment Saved!");
    } catch (error) {
      console.error("Error saving comment to S3:", error);
    }
  };

  const handleDownloadDXF = async () => {
    try {
      const params = {
        apikey: "147d31f29822ee21861aaebcf33e7a1c",
        input: "url",
        file: outputFile,
        filename: partId,
        outputformat: "dxf",
        options: "",
      };

      const uploadResponse = await axios.post(
        "https://api.convertio.co/convert",
        params
      );
      const { id, minutes } = uploadResponse.data.data;
      alert(
        `Please wait for ${minutes} minutes. File is being processed in Convert.io`
      );
      setDxfId(id);
      setDxfMinutes(minutes);
      setDxfButtonLabel(`Get File in ${minutes} Minutes`);
    } catch (error) {
      console.error("Error converting SVG to DXF:", error);
      alert("Error Uploading the File in Convert.io");
    }
  };

  const handleGetFile = async () => {
    if (!dxfId) return;

    try {
      const downloadResponse = await axios.get(
        `https://api.convertio.co/convert/${dxfId}/dl/base64`
      );
      const base64Content = downloadResponse.data.data.content;
      const dxfBlob = new Blob(
        [Uint8Array.from(atob(base64Content), (c) => c.charCodeAt(0))],
        { type: "application/dxf" }
      );
      const dxfUrl = URL.createObjectURL(dxfBlob);
      const link = document.createElement("a");
      link.href = dxfUrl;
      link.download = `${partId}.dxf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(dxfUrl);
    } catch (error) {
      console.error("Error downloading DXF file:", error);
      alert("Failed to download the DXF file!");
    }
  };

  const handleDownloadSVG = () => {
    if (svgBlob) {
      const url = URL.createObjectURL(svgBlob);
      const link = document.createElement("a");
      link.href = url;

      const fileName = outputFile.split('/').pop();
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      alert("No SVG available to download.");
    }
  };

  useEffect(() => {
    if (dxfMinutes !== null) {
      const interval = setInterval(() => {
        setDxfMinutes((prevMinutes) => {
          if (prevMinutes > 1) {
            return prevMinutes - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [dxfMinutes]);

  useEffect(() => {
    setDxfButtonLabel(
      dxfMinutes !== null ? `Get File in ${dxfMinutes} Minutes` : "Download DXF"
    );
  }, [dxfMinutes]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          Welcome to the SVG Viewer. Don't forget to leave a comment
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: "70%",
          height: "100%",
          overflow: "auto",
          padding: "2%",
        }}
      >
        {svgUrl && (
          <img
            src={svgUrl}
            alt="SVG"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "30%",
          height: "100%",
          borderLeft: "1px solid #ccc",
          padding: "3%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Leave a Feedback:
        </Typography>
        <TextField
          label="Comment"
          type="text"
          fullWidth
          value={comment}
          onChange={handleCommentChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#ff5f00",
              },
              "&:hover fieldset": {
                borderColor: "#ff5f00",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#ff5f00",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#ff5f00",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#ff5f00",
            },
          }}
          style={{ margin: "3% 0" }}
        />
        <br />
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Experience:
        </Typography>
        <Rating
          name="simple-controlled"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
        <br />
        <br />
        <center>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCommentSave}
              style={{
                border: "1px solid #ff5f00",
                borderRadius: "20px",
                backgroundColor: "white",
                color: "black",
                margin: "1% 0.3%",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Save style={{ marginRight: "8px" }} />
              <span>Save Comments</span>
            </Button>
            <Button
              variant="outlined"
              onClick={handleDownloadSVG}
              style={{
                border: "1px solid #ff5f00",
                borderRadius: "20px",
                backgroundColor: "white",
                color: "black",
                margin: "1% 0.3%",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CloudDownload style={{ marginRight: "8px" }} />
              <span>Download SVG</span>
            </Button>
          </div>
          <Button
            variant="outlined"
            onClick={
              dxfButtonLabel.startsWith("Get File in")
                ? handleGetFile
                : handleDownloadDXF
            }
            style={{
              border: "1px solid #ff5f00",
              borderRadius: "20px",
              backgroundColor: "white",
              color: "black",
              margin: "1% 0.3%",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CloudDownload style={{ marginRight: "8px" }} />
            <span>{dxfButtonLabel}</span>
          </Button>
        </center>
      </Box>
    </Box>
  );
};

export default Viewer;
